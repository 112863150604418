@import "mixins/breakpoints";
@import "variables";

.audio-wave-visualizer {
  position: relative;
  padding: 22px;
  z-index: 1;

  @include media-breakpoint-up(md) {
    padding: 44px;
  }

  .audio-wave__toggle {
    position: relative;
    display: block;
    margin: 0 auto;
    width: 258px;
    height: 54px;
    z-index: 1;

    @include media-breakpoint-up(sm) {
      width: 386px;
    }

    &[data-checked="false"] {
      .audio-wave__slider {
        &:before {
          background-color: $orange-500;
          transform: translateX(118px);

          @include media-breakpoint-up(sm) {
            transform: translateX(182px);
          }
        }
      }
    }

    .audio-wave__slider {
      display: flex;
      align-items: space-around;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #2B343D;
      transition: .4s;
      border-radius: 34px;

      &:before {
        position: absolute;
        content: "";
        height: 43px;
        width: calc(258px / 2);
        border-radius: 50px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        left: 6px;
        bottom: 6px;
        background-color: $white;
        transition: .4s;

        @include media-breakpoint-up(sm) {
          left: 6px;
          width: calc(384px / 2);
        }
      }
    }

    button {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      flex-basis: 50%;
      position: relative;
      background-color: transparent;
      border: none;
      font-weight: 700;
      padding: 14px 16px;

      &:first-of-type {
        color: #58626B;

        &.is-active {
          color: #58626B;
        }
      }

      &:last-of-type {
        color: #58626B;

        &.is-active {
          color: $white;
        }
      }

      &.is-active {
        pointer-events: none;
      }
    }
  }

  .audio-wave__controls {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 36px;
    width: 100%;
    z-index: 2;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      gap: 54px;
    }

    button {
      display: inline-flex;
      border: none;
      background-color: #2B343D;
      color: $white;
      border-radius: 50px;
      padding: 24px;

      span {
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        pointer-events: none;
      }

      svg {
        width: 24px;
        height: 24px;
      }

      &[data-paused="true"] {
        .audio-wave__play-icon {
          display: inline-flex;
        }

        .audio-wave__pause-icon {
          display: none;
        }
      }

      &[data-paused="false"] {
        .audio-wave__play-icon {
          display: none;
        }

        .audio-wave__pause-icon {
          display: inline-flex;
        }
      }

      &[data-muted="true"] {
        .audio-wave__mute-icon {
          display: none;
        }

        .audio-wave__unmute-icon {
          display: inline-flex;
        }
      }

      &[data-muted="false"] {
        .audio-wave__mute-icon {
          display: inline-flex;
        }

        .audio-wave__unmute-icon {
          display: none;
        }
      }
    }
  }

  .audio-wave__wavesurfer {
    display: block;
    width: 100%;

    &.is-loading {
      // loading styles
    }

    >wave {
      cursor: ew-resize;
    }
  }
}

.audio-wave__container {
  display: flex;
  gap: 48px;
  flex-direction: column;
  align-items: center;
  max-width: 720px;
  margin: 0 auto;
}

.audio-wave__background-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 0;
  pointer-events: none;

  display: none;

  &.is-active {
    display: block;
  }
}
